/* Apply styles to the entire application */
body {
  margin: 0;
  font-family: sans-serif;
}

.App {
  padding: 0.5rem 3.5rem;
  overflow: hidden;
}

/* Define CSS variables for reusability */
:root {
  --clr: transparent;
  --global-text-gradient: radial-gradient(circle at 10% 20%, rgb(0, 93, 13) 0%, #03fafa 95%);
  --global-text-gradient-hover: radial-gradient(circle at 10% 20%, rgb(184, 249, 193) 0%, #f1f8f9 90%);
  --LogoColor: #008b8b;
  --Color2:darkblue;
}

/* Apply responsive styles */
@media screen and (max-width: 480px) {
  .App {
    padding: 0.5rem 1rem;
  }
}
