

/* Landing Container */
.P2H-landing-container {
  display: flex;
  align-items: center;
  padding: 2rem;
  height: auto;
}

/* Left Section */
.P2H-left-section {
  flex: 1;
  padding-right: 2rem;
}

/* Right Section */
.P2H-right-section {
  flex: 1;
}

.P2H-content-wrapper {
  max-width: 600px;
  margin: 0 auto;
}

.P2H-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
  line-height: 1.2;
}

.P2H-highlight {
  list-style-type: none;
  margin-bottom: 2rem;
}

.P2H-highlight li {
  font-size: 17px;
  color: black;
  line-height: 1.5;
}

.P2H-highlight li::before {
    content: '\25CF';
  color: var(--LogoColor);
  margin-right: 0.75rem;
  font-weight: bold;
  font-size: 1.2rem;
}

.P2H-highlight-item {
  font-weight: 600;
  color: var(--LogoColor);
}

.P2H-image-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.P2H-main-image {
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
}

/* Responsive design adjustments */
@media (max-width: 992px) {
  .P2H-landing-container {
    padding: 2rem 1.5rem;
  }
  
  .P2H-left-section {
    padding-right: 1.5rem;
  }
  
  .P2H-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 768px) {
  .P2H-landing-container {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .P2H-left-section {
    order: 1;
    padding-right: 0;
    padding-bottom: 2rem;
  }

  .P2H-right-section {
    order: 2;
  }

  .P2H-content-wrapper {
    max-width: 100%;
  }

  .P2H-title {
    font-size: 1.6rem;
    text-align: center;
  }

  .P2H-highlight li {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .P2H-landing-container {
    padding: 1.5rem 1rem;
  }

  .P2H-title {
    font-size: 1.4rem;
  }

  .P2H-highlight li {
    font-size: 0.8rem;
  }

  .P2H-highlight li::before {
    font-size: 1rem;
    margin-right: 0.5rem;
  }
}

.P2H-inline-link {
    color: #0071e3;
    text-decoration: none;
    transition: opacity 0.3s ease;
  }
  
  .P2H-inline-link:hover {
    opacity: 0.7;
  }

  .linkKC {
    text-decoration: none;
    color: var(--LogoColor); /* Use variable for color */
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: 2px solid var(--LogoColor); /* Border with LogoColor */
    border-radius: 0.3rem; /* Rounded corners */
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; /* Smooth transitions */
  
    /* Center align text and vertically center */
    display: inline-block;
    text-align: center;
    line-height: normal;
  }
  
  .linkKC:hover {
    background-color: var(--LogoColor); /* Background color on hover */
    color: white; /* White text on hover */
  }
  