/* Navbar.css */

@import url("https://fonts.cdnfonts.com/css/sangira");
@import url("https://fonts.googleapis.com/css2?family=Urbanist&display=swap");

.krysta-link {
  text-decoration: none;
  color: inherit;
}
.krysta {
  font-family: "Sangira", sans-serif;
  color: white;
  font-size: 1.3rem;
  padding: 0.1rem 0.5rem;
  letter-spacing: 1.5px;
  background: darkcyan;
  margin-right: 0px; /* Adjust the value as needed */
  display: inline-block;
  position: relative;
  overflow: hidden;
}

.krysta::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.1
  ); /* Adjust the overlay color and opacity */
  transition: left 0.3s ease; /* Adjust the duration and timing function */
}

.krysta:hover::before {
  left: 100%;
}

.navbar {
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: "Urbanist", sans-serif;
}

.navbar-logo {
  font-family: "Sangira", sans-serif;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  padding: 0.5rem;
  text-decoration: none;
  display: inline-block;
  transition: color 0.3s ease-in-out;
  background: darkcyan;
}

.navbar-toggle {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 100;
  transition: transform 0.3s ease;
}

.navbar-toggle:hover {
  transform: scale(1.1);
}

.navbar-toggle .icon {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 22px;
  transition: all 0.3s ease;
}

.navbar-toggle .icon::before,
.navbar-toggle .icon::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: var(--LogoColor);
  left: 0;
  transition: all 0.3s ease;
}

.navbar-toggle .icon::before {
  top: 0;
}

.navbar-toggle .icon::after {
  bottom: 0;
}

.navbar-toggle.active .icon {
  transform: rotate(180deg);
}

.navbar-toggle.active .icon::before {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

.navbar-toggle.active .icon::after {
  bottom: 50%;
  transform: translateY(50%) rotate(-45deg);
}

@keyframes pulseGlow {
  0% {
    box-shadow: 0 0 0 0 rgba(3, 85, 23, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(3, 85, 23, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(3, 85, 23, 0);
  }
}

.navbar-toggle:focus {
  outline: none;
}

.navbar-menu {
  list-style-type: none;
  display: flex;
  margin: 0;
}

.navbar-item {
  margin-right: 1rem;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.navbar-item:nth-child(1) {
  animation-delay: 0.1s;
}

.navbar-item:nth-child(2) {
  animation-delay: 0.2s;
}

.navbar-item:nth-child(3) {
  animation-delay: 0.3s;
}

.navbar-item:nth-child(4) {
  animation-delay: 0.4s;
}

.navbar-item:nth-child(5) {
  animation-delay: 0.5s;
}

@keyframes fadeIn {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.navbar-menu a {
  text-decoration: none;
  color: #1e1a1a;
  position: relative;
  padding: 5px;
  transition: all 0.7s ease-in-out;
  font-family: sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
}

.navbar-menu a::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: transparent;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.navbar-menu a:hover {
  color: white;
  background-color: var(--LogoColor);
  padding: 5px;
}

.navbar-menu a:hover::before {
  visibility: visible;
  background-color: black;
  transform: scaleX(1);
}

 /* CSS rules for small laptops and larger screens */
@media (min-width: 1024px) {
  .navbar-toggle {
    display: flex;
    order: 2;
    color: black;
    padding-top: 0.4rem;
  }

  .navbar-menu {
    flex-direction: column;
    position: absolute;
    top: 5rem;
    right: 0;
    background-color: #f3ebeb;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 13;
  }

  .navbar-menu.active {
    opacity: 1;
    visibility: visible;
    padding-top: 7rem;
    background: white;
  }

  .navbar-item {
    margin: 10px 0;
    font-weight: lighter;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .navbar-item a {
    color: rgb(13, 12, 12);
  }

  .navbar-logo {
    height: auto;
  }
}



 /* CSS rules for mobile screens */

@media (max-width: 768px) {
  .navbar-toggle {
    display: flex;
    order: 2;
    color: black;
    padding-top: 0.4rem;
  }

  .navbar-menu {
    flex-direction: column;
    position: absolute;
    top: 5rem;
    right: 0;
    background-color: #f3ebeb;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    z-index: 13;
  }

  .navbar-menu.active {
    opacity: 1;
    visibility: visible;
    padding-top: 7rem;
    background: white;
  }

  .navbar-item {
    margin: 10px 0;
    font-weight: lighter;
    opacity: 0;
    animation: fadeIn 0.5s ease forwards;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .navbar-item a {
    color: rgb(13, 12, 12);
  }

  .navbar-logo {
    height: auto;
  }
}
