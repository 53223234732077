.P4H-app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
  color: #1d1d1f;
  background-color: #ffffff;
  line-height: 1.47059;
  letter-spacing: -0.022em;
}

.P4H-title {
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2rem;
}

.P4H-features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  text-align:left;

}

.P4H-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

/* .P4H-card:hover {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.15);
  transform: translateY(-5px);
} */

.P4H-card h2 {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.8rem;
  color: var(--LogoColor);
}

.P4H-card p {
  font-size: 17px;
  color: black;
  line-height: 1.5;
}


@media (max-width: 734px) {
  .P4H-title { font-size: 40px; }
  .P4H-features { grid-template-columns: 1fr; gap: 1.5rem; }
  .P4H-card { padding: 1.5rem; }
  .P4H-card h2 { font-size: 21px; }
  .P4H-card p { font-size: 16px; }
}

@media (max-width: 480px) {
  .P4H-title { font-size: 32px; }
  .P4H-cta { display: block; width: 100%; }
}