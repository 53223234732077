/* Base styles for larger screens */
.footer {
  background: var(--LogoColor);
  color: white;
  padding: 50px 0;
  font-family: 'Roboto', sans-serif;
  margin-top: 2rem;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.LoginButton{
  color: white;
  text-decoration: none;
  font-size: 14px;
  margin-right: 20px;
}
/* .LoginButton:hover{
  background-color: #fff;
  color: black;
  padding: 8px;

} */

.footer-logo {
  display: flex;
  align-items: center;
}

.krystaFooter{
    font-family: "Sangira", sans-serif;
    color: var(--LogoColor);
    font-size: 1.3rem;
    padding: 0.1rem 0.5rem;
    letter-spacing: 1.5px;
    background: white;
    margin-right: 0px; /* Adjust the value as needed */
    display: inline-block;
    position: relative;
    overflow: hidden;
}

.footer-logo h2 {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.footer-contact h3,
.footer-social h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: white;
}

.footer-contact p {
  font-size: 14px;
  margin: 0;
  color: white;
}

.contact-info.phone {
  font-size: 14px;
  margin: 0;
  color: white;
}

.contact-info.email {
  font-size: 14px;
  margin: 0;
  color: white;
}

.footer-social ul {
  list-style: none;
  padding: 0;
  color: white
}

.social-link {
  color: #a8b2d1;
  text-decoration: none;
  font-size: 14px;
  margin-right: 20px;
  color: white;
}

.social-link:hover {
  color: white;
}

/* Style links within the footer-contact section */
.footer-contact p a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.footer-contact p a:hover {
  color: white;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer-logo img {
    margin-right: 0;
  }

  .footer-contact,
  .footer-social {
    text-align: center;
  }

  .social-link {
    margin-right: 10px;
  }
}

/* Deeply responsive styles for various screen types */
@media (max-width: 480px) {
  .footer-contact h3,
  .footer-social h3 {
    font-size: 16px;
    margin-top: 1rem;
  }

  .footer-contact p {
    font-size: 12px;
  }

  .footer-social ul {
    margin-top: 10px;
    text-align: center;
  }

  .social-link {
    font-size: 0.6rem;
    margin-right: 10px;
    
  }

  /* Style links within the footer-contact section for smaller screens */
  .footer-contact p a {
    font-size: 12px;
  }
}
