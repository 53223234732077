.P4H-about-container {
  padding: 20px;
  background-color: #fff;
  max-width: 800px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
  text-align: center;
}

.P4H-heading {
  font-size: 2em;
  color: #2c3e50;
  margin-bottom: 20px;
}

.P4H-description {
  font-size: 1em;
  color: #7f8c8d;
  line-height: 1.6;
  margin-bottom: 15px;
}

.P4H-description:first-of-type {
  font-weight: bold;
  color: #34495e;
}

@media (max-width: 768px) {
  .P4H-about-container {
    padding: 10px;
  }

  .P4H-heading {
    font-size: 1.5em;
  }

  .P4H-description {
    font-size: 0.8em;
  }
}
