/* General Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }
  
  /* Landing Container */
  .landing-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding: 2rem;
    background: #ffffff;
    height: auto;
    overflow: hidden;
  }
  
  @media (min-width: 768px) {
    .landing-container {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  
  /* Left Section */
  .left-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .content-wrapper {
    max-width: 600px;
    text-align: center;
  }
  
  .title {
    font-size: 1.5rem; /* Larger font size for emphasis */
    font-weight: bold;
    margin-bottom: 0.5rem; /* Improved spacing */
    color: #333; /* Dark color for readability */
  }
  
  .highlight {
    display: block;
    height: 0px; /* Adjusted height for highlight */
    background-color: var(--LogoColor); /* Replace with your color */
    margin: 1rem auto; /* Centered and spaced */
  }
  
  .subtitle1 {
    font-size: 0.85rem; /* Slightly larger font size */
    color: #555; /* Lighter color */
    margin-bottom: 1.5rem; /* Improved spacing */
    
  }
  
  
  .additional-info {
    margin: 2rem 0; /* Added margin for separation */
    padding: 1rem;
    background-color: #f4f4f4; 
    border: 1px solid #ddd; /* Border for separation */
    border-radius: 5px;
    text-align: center;
  }
  
  .additional-info p {
    font-size: 0.7rem; /* Adjusted font size for readability */
    color: #333; /* Dark color for readability */
  }
  
  .cta-container {
    display: flex;
    gap: 1.5rem;
    justify-content: center;
    margin-bottom: 2rem; /* Increased margin for spacing */
  }
  
  .cta-button {
    padding: 0.75rem 1.5rem;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    text-decoration: none;
  }
  
  .cta-button.primary {
    background: var(--LogoColor); /* Primary button color */
    color: #fff;
  }
  
  .cta-button.secondary {
    background: #fff;
    color: var(--LogoColor); /* Secondary button color */
    border: 2px solid var(--LogoColor); /* Border for secondary button */
  }
  
  .cta-button:hover {
    transform: translateY(-3px);
  }
  
  .stats {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2rem; /* Increased margin for spacing */
  }
  
  .stat-item {
    flex: 1 1 100%; /* Full width for each item */
    text-align: center;
  }
  
  .stat-number {
    font-size: 1.5rem; /* Increased font size for emphasis */
    font-weight: bold;
    color: #333; /* Dark color for readability */
  }
  
  .stat-label {
    font-size: 1rem; /* Font size for readability */
    color: #555; /* Lighter color */
  }
  
  /* Right Section */
  .right-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 2rem; /* Increased margin for spacing */
  }
  
  .image-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    
  }
  
  .AIBOT-image {
    width: 100%;
    max-width: 400px; /* Adjust as needed */
    height: auto;
    border-radius: 10px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }
  
  
  .floating-element {
    position: absolute;
    border-radius: 50%;
    opacity: 0.6;
    animation: float 4s ease-in-out infinite;
  }
  
  .e1 {
    width: 80px; /* Size of floating elements */
    height: 80px;
    background: var(--LogoColor); /* Floating element color */
    top: 10%;
    left: 10%;
  }
  
  .e2 {
    width: 120px; /* Size of floating elements */
    height: 120px;
    background: var(--LogoColor); /* Floating element color */
    bottom: 15%;
    right: 3%;
  }
  
  .e3 {
    width: 60px; /* Size of floating elements */
    height: 60px;
    background: var(--LogoColor); /* Floating element color */
    bottom: 8%;
    left: 45%;
  }
  
  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px); /* Floating animation */
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .title {
      font-size: 1rem; /* Adjusted size for small screens */
    }
  
    .subtitle {
      font-size: 0.8rem; /* Adjusted size for small screens */
    }
  
    .cta-container {
      flex-direction: column;
      align-items: center;
    }
  
    .cta-button {
      width: 70%; /* Adjusted width for small screens */
      font-size: 0.9rem; /* Adjusted font size */
      padding: 0.5rem 1rem; /* Adjusted padding */
    }
  
    .stats {
      flex-direction: column;
    }
  
    .stat-number {
      font-size: 1.2rem; /* Adjusted size for small screens */
    }
  
    .stat-label {
      font-size: 0.9rem; /* Adjusted size for small screens */
    }
  
    .e1 {
      width: 50px; /* Adjusted size */
      height: 50px;
      top: 10%;
      left: 10%;
    }
    
    .e2 {
      width: 80px; /* Adjusted size */
      height: 80px;
      bottom: 15%;
      right: 0.5%;
    }
    
    .e3 {
      width: 40px; /* Adjusted size */
      height: 40px;
      bottom: 11%;
      left: 45%;
    }
  }
  