.P3H-container {
    color: #1d1d1f;
    padding: 1rem;
    max-width: 1100px;
    margin: 0 auto;
  }
  
  .P3H-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .P3H-bento-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }
  
  .P3H-image-container {
    grid-column: span 2;
    grid-row: span 2;
    border-radius: 0.5rem;
    overflow: hidden;
  }
  
  .P3H-main-image {
    width: 90%;
    height: 90%;
    object-fit: cover;
  }
  
  .P3H-benefit-item {
    background-color: #ffffff;
    padding: 0.75rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .P3H-benefit-item h3 {
    font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.8rem;
  color: var(--LogoColor);
  }
  
  .P3H-benefit-item p {
    font-size: 17px;
    color: black;
    line-height: 1.5;
  }
  
  .P3H-cta-container {
    grid-column: span 3;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
  
  .P3H-cta-button {
    padding: 0.75rem 1.5rem;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    border-radius: 5px;
    transition: transform 0.3s ease, background-color 0.3s ease;
    text-decoration: none;
  }
  
  .P3H-primary {
    background-color:var(--LogoColor);
    color: #ffffff;
  }
  
  .P3H-secondary {
    background-color: #ffffff;
    color: #0071e3;
    border: 1px solid #0071e3;
  }
  
  .P3H-cta-button:hover {
    transform: translateY(-3px);
  }

  
  @media (max-width: 600px) {
    .P3H-bento-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  
    .P3H-image-container {
      grid-column: span 2;
      grid-row: span 1;
    }
  
    .P3H-cta-container {
      grid-column: span 2;
    }
  }
  
  @media (max-width: 400px) {
    .P3H-bento-grid {
      grid-template-columns: 1fr;
    }
  
    .P3H-image-container {
      grid-column: span 1;
    }
  
    .P3H-cta-container {
      grid-column: span 1;
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    
  
    .P3H-cta-button{
      width: 70%; /* Adjusted width for smaller screens */
      font-size: 0.9rem; /* Adjusted font size for smaller screens */
      padding: 0.5rem 0.5rem; /* Adjusted padding for smaller screens */
      text-align: center;
    }

    .P3H-benefit-item h3 {
        font-size: 0.95rem;
        margin-bottom: 0.25rem;
        /* font-weight: bold; */
        color: var(--LogoColor);
      }

      .P3H-benefit-item p {
        font-size: 0.75rem;
  
      }
}